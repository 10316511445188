
import { validationRules } from '@icepanel/app-form'
import { Landscape, OrganizationUser, PermissionType } from '@icepanel/platform-api-client'
import { addDays } from 'date-fns'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { RecycleScroller } from 'vue-virtual-scroller'
import { getModule } from 'vuex-module-decorators'

import ContextMenuContent from '@/components/context-menu/content.vue'
import ContextMenu from '@/components/context-menu/index.vue'
import ContextMenuItem from '@/components/context-menu/item.vue'
import contains from '@/helpers/contains'
import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  components: {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    RecycleScroller
  },
  name: 'OrganizationUserInvite'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  @Ref() readonly permissionMenuRef!: ContextMenu
  @Ref() readonly landscapesMenuRef!: ContextMenu

  userPermission: PermissionType = 'write'
  userEmail: string[] = []
  userLandscapePermissions: OrganizationUser['landscapePermissions'] = {}

  showInviteError = false
  sendingInvite = false
  sentInvite = false

  loadingBillingLink = false

  inviteEmailModel = ''

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get organizationUsers () {
    if (this.organizationModule.organizationUsers[this.currentOrganization.id]) {
      return Object.entries(this.organizationModule.organizationUsers[this.currentOrganization.id]).map(([id, o]) => ({
        ...o,
        id
      }))
    }
  }

  get usedEditorSeats () {
    return this.organizationUsers?.filter(o => contains(o.permission, ['write', 'admin'])).length || 0
  }

  get totalEditorSeats () {
    return this.currentOrganization.seats
  }

  get emptyEditorSeats () {
    return this.organizationUsers ? Math.max(0, this.totalEditorSeats - this.usedEditorSeats) : 0
  }

  get pendingEditorSeats () {
    let count = 0
    if (this.userEmail && this.userPermission !== 'billing' && this.userPermission !== 'read') {
      count += this.userEmail.length
    }
    return count
  }

  get userEmailValidation () {
    return this.userEmail.map(o => validationRules.email.map(v => v(o)).find(v => v !== false))
  }

  get userEmailValidationSuccessful () {
    return this.userEmail.length && (!this.userEmailValidation.length || this.userEmailValidation.every(o => o === true))
  }

  get selectedPermissionInfo () {
    return this.permissionTypes.find((p) => p.id === this.userPermission)
  }

  get selectedUserLandscapePermissions () {
    return this.userLandscapePermissions
  }

  get permissionTypes (): { id: PermissionType, icon: string, text: string, value: string }[] {
    return [
      {
        icon: 'fad-user-pen',
        id: 'write',
        text: 'Editor',
        value: 'write'
      },
      {
        icon: 'fad-eye',
        id: 'read',
        text: 'Viewer',
        value: 'read'
      },
      {
        icon: 'fad-user-gear',
        id: 'admin',
        text: 'Admin',
        value: 'admin'
      },
      {
        icon: 'fad-credit-card',
        id: 'billing',
        text: 'Billing',
        value: 'billing'
      }
    ]
  }

  get permissionInfo (): Record<PermissionType, { text: string, icon: string }[]> {
    return {
      admin: [
        {
          icon: 'fad-users',
          text: 'Add and remove team members'
        },
        {
          icon: 'fad-trash-can',
          text: 'Remove landscapes'
        },
        {
          icon: 'fad-link',
          text: 'Edit and set up integrations'
        }
      ],
      billing: [
        {
          icon: 'fad-users',
          text: 'Add and remove team members'
        },
        {
          icon: 'fad-credit-card',
          text: 'Update payment information'
        }
      ],
      read: [
        {
          icon: 'fad-eye',
          text: 'View up to date designs & docs'
        },
        {
          icon: 'fad-question',
          text: 'Add questions and ideas'
        },
        {
          icon: 'fad-thumbs-down',
          text: 'Mark designs as inaccurate'
        }
      ],
      write: [
        {
          icon: 'fad-pencil',
          text: 'Design & document together'
        },
        {
          icon: 'fad-people-arrows',
          text: 'Add and share their own expertise'
        },
        {
          icon: 'fad-user-hard-hat',
          text: 'Easily maintain and update docs'
        }
      ]
    }
  }

  get landscapeDropdownItems (): { id: string, name: string, selected: boolean }[] {
    const landscapes = this.landscapes.map((l) => {
      return {
        id: l.id,
        name: l.name,
        selected: !this.userLandscapePermissions || this.userLandscapePermissions[l.id]
      }
    })

    return [
      {
        id: 'all',
        name: 'All (default)',
        selected: this.userLandscapePermissions === undefined
      },
      ...landscapes
    ]
  }

  get landscapes () {
    return this.landscapeModule.landscapes
  }

  get landscapePermissions () {
    let userLandscapePermissions = 'All (default)'

    const value = this.userLandscapePermissions
    if (value) {
      const landscapes = Object
        .entries(value)
        .filter(([, permission]) => permission)
        .map(([id]) => this.landscapes.find(o => o.id === id))
        .filter((o): o is Landscape => !!o)
      if (landscapes.length) {
        userLandscapePermissions = landscapes.map(o => o.name).join(', ')
      } else {
        userLandscapePermissions = 'None'
      }
    }

    return userLandscapePermissions
  }

  get canInvite () {
    return this.userEmail.length && this.userPermission.length && this.validateSeats(this.userPermission)
  }

  get inviteError () {
    if (!this.validateSeats(this.userPermission)) {
      return this.emptyEditorSeats === 1 ? 'Only 1 editor left' : `Only ${this.emptyEditorSeats} editors left`
    } else if (this.userEmail.length === 0) {
      return 'No valid emails have been added'
    } else {
      return undefined
    }
  }

  async invite () {
    this.showInviteError = false
    if (!this.canInvite || this.sendingInvite || this.inviteError) {
      this.showInviteError = true
      return
    }

    this.sendingInvite = true

    await Promise.all(this.userEmail.map(o => this.createUserInvite(o, this.userPermission, this.userLandscapePermissions)))

    this.reset()
    this.sentInvite = true
    this.sendingInvite = false
    this.$emit('invite')

    setTimeout(() => {
      this.reset()
    }, 4000)
  }

  mounted () {
    analytics.organizationUserInviteScreen.track(this, {
      landscapeId: this.currentLandscape ? [this.currentLandscape.id] : [],
      organizationId: [this.currentOrganization.id]
    })

    this.userLandscapePermissions = undefined
  }

  reset () {
    this.userPermission = 'write'
    this.userEmail = []
    this.userLandscapePermissions = undefined
    this.sentInvite = false
  }

  validateSeats (permission: PermissionType) {
    if (permission === 'admin' || permission === 'write') {
      return this.pendingEditorSeats <= this.emptyEditorSeats
    } else {
      return true
    }
  }

  async addInviteEmail () {
    // wait for clipboard contents
    await new Promise(resolve => setTimeout(resolve))

    const commaSeperatedEmails = this.inviteEmailModel
      .toLowerCase()
      .split(/,/gm)
      .map(o => /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/.exec(o)?.[0] ?? '')
      .filter(o => o && !this.userEmail.includes(o))

    if (commaSeperatedEmails.length) {
      commaSeperatedEmails.forEach(o => this.userEmail.push(o))
      this.inviteEmailModel = ''
    }
  }

  removeInviteEmail (email?: string, event?: KeyboardEvent) {
    if (email) {
      this.userEmail = this.userEmail.filter(o => o !== email)
    } else if (!this.inviteEmailModel && this.userEmail.length) {
      this.inviteEmailModel = this.userEmail[this.userEmail.length - 1]
      this.userEmail = this.userEmail.slice(0, -1)
      event?.preventDefault()
    }
  }

  async createUserInvite (email: string, permission: PermissionType, landscapePermissions?: Record<string, boolean>) {
    const organizationUserInvite = await this.organizationModule.organizationUserInviteCreate({
      invite: {
        email,
        expiresAt: addDays(new Date(), 7).toISOString(),
        landscapePermissions,
        permission
      },
      organizationId: this.currentOrganization.id
    })

    analytics.organizationUserInviteCreate.track(this, {
      landscapeId: this.currentLandscape ? [this.currentLandscape.id] : undefined,
      organizationId: [this.currentOrganization.id],
      userEmail: organizationUserInvite.email,
      userExists: !!organizationUserInvite.usedAt,
      userPermission: permission
    })
  }

  async editBilling () {
    try {
      if (this.loadingBillingLink) {
        return
      }
      this.loadingBillingLink = true

      const returnUrl = new URL(window.location.href)
      returnUrl.searchParams.set('billing_organization', this.currentOrganization.id)

      const links = await this.organizationModule.organizationBillingLinkCreate({
        organizationId: this.currentOrganization.id,
        returnUrl: returnUrl.toString()
      })

      analytics.organizationBillingLink.track(this, {
        organizationId: [this.currentOrganization.id]
      })

      await new Promise(resolve => setTimeout(resolve, 1000))

      window.location.href = links.updateSubscriptionPlanUrl || links.url
    } catch (err: any) {
      this.loadingBillingLink = false
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    }
  }

  updateLandscapePermissions (landscapeId: string) {
    if (landscapeId === 'all') {
      this.userLandscapePermissions = this.userLandscapePermissions ? undefined : {}
    } else if (landscapeId) {
      const value = this.userLandscapePermissions
      if (value) {
        if (value[landscapeId]) {
          Vue.delete(value, landscapeId)
        } else {
          Vue.set(value, landscapeId, true)
        }
      } else {
        this.userLandscapePermissions = this.landscapes.reduce<Record<string, boolean>>((p, c) => {
          if (c.id === landscapeId) {
            return p
          } else {
            return {
              ...p,
              [c.id]: true
            }
          }
        }, {})
      }
    }
  }

  goToManageOrganization () {
    this.$router.push({
      name: 'organization-manage-members',
      params: {
        organizationId: this.currentOrganization.id
      }
    })
  }
}
